<template>
  <div>
    <CyHeader v-if="environment">
      <template #title>
        <router-link :to="{ name: 'project', params: { projectCanonical } }">
          {{ projectName }}
        </router-link>
        <span
          class="separator"
          v-text="'/'"/>
        <CyEnvironmentMenu
          :pipelines="extractStartStopPipelines(pipelines).regular"
          :current-env.sync="environment"
          :envs="envs"/>
      </template>
      <template #actions>
        <CyMenu
          v-if="!_.$isEmpty($static.menuItems)"
          class="cy-project-card-header__menu"
          offset-y
          left
          :items="$static.menuItems">
          <template #subheader>
            <v-subheader>
              <div class="v-subheader__title cy-headline">
                {{ $t('Environment') }}
              </div>
              <div class="v-subheader__environment">
                <CyAvatar
                  :item="environment"
                  sm/>
                <span class="canonical">
                  {{ environment.canonical }}
                </span>
              </div>
            </v-subheader>
          </template>
          <template #activator="{ on, attrs }">
            <CyButton
              icon="more_horiz"
              theme="primary"
              variant="secondary"
              v-bind="attrs"
              v-on="on">
              {{ $t('more') }}
            </CyButton>
          </template>
        </CyMenu>
      </template>
      <template #metadata>
        <v-menu
          v-if="stackCanonical"
          open-on-hover
          open-delay="200"
          close-delay="200"
          offset-y>
          <template #activator="{ on }">
            <CyHeaderMetadata
              icon="fa-cubes"
              :to="{ name: 'stack', params: { stackRef } }"
              :label="stackCanonical"
              v-on="on"/>
          </template>
          <CyWizardServiceCard
            class="border-none"
            :max-width="440"
            data-cy="stack-card-summary"
            :service="project.service_catalog"/>
        </v-menu>
        <CyTooltip
          v-if="environment.use_case"
          bottom>
          <template #activator="{ on }">
            <CyHeaderMetadata
              icon="fa-cube"
              :label="environment.use_case"
              v-on="on"/>
          </template>
          {{ $t('environment.useCase') }}
        </CyTooltip>

        <CyHeaderMetadata v-if="environment.cloud_provider_canonical">
          <CyIconCredential
            class="mr-0"
            size="20"
            :type="environment.cloud_provider_canonical"/>
        </CyHeaderMetadata>
        <CyHeaderMetadata
          v-if="environment.created_at"
          :label="getLabel('created')"/>
        <CyHeaderMetadata
          v-if="environment.updated_at"
          :label="getLabel('updated')"/>
      </template>
    </CyHeader>

    <CyFormsEnvironmentDelete
      v-if="modal"
      :environment="environment"
      :project-canonical="projectCanonical"
      @cancel="$toggle.modal()"
      @success="onDeleteSuccess"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { extractStartStopPipelines } from '@/utils/helpers'
import CyHeader from '@/components/header'
import CyHeaderMetadata from '@/components/header/metadata'
import CyFormsEnvironmentDelete from '@/components/forms/environment-delete'
import CyEnvironmentMenu from '@/components/environment/menu'
import CyWizardServiceCard from '@/components/wizard/service-card'

export default {
  name: 'CyHeaderVariantEnvironment',
  components: {
    CyHeader,
    CyHeaderMetadata,
    CyFormsEnvironmentDelete,
    CyEnvironmentMenu,
    CyWizardServiceCard,
  },
  data: () => ({
    modal: false,
    environment: null,
  }),
  computed: {
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
      projectErrors: (state) => state.errors,
    }),
    ...mapGetters('organization/project', [
      'project',
      'stackCanonical',
      'stackRef',
      'envs',
    ]),
    $static () {
      const { projectCanonical, envCanonical } = this
      const { canDisplay } = this.$cycloid.permissions
      const backRouteTo = this.$route.name
      return {
        menuItems: [
          {
            icon: 'construction',
            label: this.$t('Settings'),
            action: () => {
              this.$router.push({
                name: 'projectConfigurationEnvironment',
                params: { envCanonical, backRouteTo },
              })
            },
            permissionKey: 'UpdateProject',
          },
          {
            key: 'clone',
            icon: 'content_copy',
            label: `${this.$t('clone')}...`,
            action: () => {
              this.$router.push({
                name: 'environmentClone',
                params: { envCanonical, backRouteTo },
              })
            },
            permissionKey: 'UpdateProject',
          },
          {
            icon: 'delete',
            label: `${this.$t('forms.btnDelete')} ${this.$t('Environment')}...`,
            action: () => { this.modal = true },
            color: 'error',
            permissionKey: 'UpdateProject',
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, projectCanonical)),
      }
    },
    projectCanonical () {
      return this.$route?.params?.projectCanonical
    },
    envCanonical () {
      return this.$route?.params?.envCanonical
    },
  },
  watch: {
    envs: {
      handler (values) {
        this.environment = _.find(values, ['canonical', this.envCanonical])
      },
      immediate: true,
    },
  },
  methods: {
    extractStartStopPipelines,
    getLabel (type) {
      const { created_at, updated_at } = this.environment
      const { format, $formatTimeAgo, $getTimestamp } = this.$date

      const labelTemplates = {
        created: this.$t('created', [format($getTimestamp(created_at), 'MMM d, yyyy')]),
        updated: this.$t('updated', [$formatTimeAgo(updated_at)]),
      }

      return labelTemplates[type] || ''
    },
    onDeleteSuccess () {
      const { projectCanonical, envs } = this
      const hasEnvs = !_.isEmpty(envs)
      const hasErrors = !_.isEmpty(this.projectErrors.env)

      if (hasErrors) return

      if (hasEnvs) {
        this.$router.push({ name: 'projectEnvironments', params: { projectCanonical } })
      } else {
        this.$router.replace({ name: 'projectEmpty', params: { projectCanonical } })
      }
    },
  },
  i18n: {
    messages: {
      en: {
        created: 'Created on {0}',
        updated: 'Updated {0}',
        more: 'More',
      },
      es: {
        created: 'Creado el {0}',
        updated: 'Actualizado {0}',
        more: 'Más',
      },
      fr: {
        created: 'Créé le {0}',
        updated: 'Mis à jour {0}',
        more: 'Plus',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.separator {
  display: flex;
  justify-content: center;
  width: 25px;
  color: get-color("primary", "light-3");
}

.credential-icon {
  margin-right: 0;
}

.router-link-active {
  color: get-color("primary", "dark-1") !important;
}

.v-subheader {
    display: flex;
    justify-content: space-between;

    &__title {
      color: get-color("grey", "dark-2")
    }

    &__environment {
      display: flex;
      align-items: center;
      margin-left: 16px;
      max-width: 160px;
      color: get-color("primary");

      .canonical {
        margin-left: 8px;
        font-size: map.get($font-sizes, "base");
        font-weight: $font-weight-bold;

        @extend %ellipsis;
      }
    }
  }
</style>
