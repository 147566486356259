var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CyMenu',{attrs:{"disabled":_vm.loadingEnvironmentsConfig,"bottom":"","content-class":"env-menu py-2","data-cy":"env-menu","min-width":"270","offset-y":"","origin":"top right","transition":"slide-y-transition"},on:{"input":function (isOpened) { return _vm.searchTerm = (isOpened ? '' : _vm.searchTerm); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var isMenuOpen = ref.value;
return [_c('div',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],class:['env-menu__activator', {
        'env-menu__activator--active': isMenuOpen,
        'disabled': _vm.loadingEnvironmentsConfig,
      }],attrs:{"data-cy":"env-menu-activator"}},on),[_c('CyAvatar',{staticClass:"env-menu__avatar mr-3",attrs:{"icon-size":"24","item":_vm.currentEnv}}),_c('span',{staticClass:"env-menu__text mr-1",attrs:{"data-cy":"env-name"}},[_vm._v(" "+_vm._s(_vm.currentEnv.canonical)+" ")]),_c('v-icon',{staticClass:"env-menu__icon mr-n1",attrs:{"size":"20"}},[_vm._v(" unfold_more ")])],1)]}}])},[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-text-field',{staticClass:"env-menu__search-field mx-2 mb-2 pa-0",attrs:{"data-cy":"search-field","placeholder":_vm.$t('environment.find'),"height":"40px","hide-details":"","autofocus":"","prepend-icon":"search"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchTerm"}})],1),_c('CyButton',{staticClass:"env-menu__create-btn mb-1",attrs:{"data-cy":"create-env-btn","variant":"tertiary","theme":"primary","height":"40px","icon":"add","to":{
      name: 'addServiceEnv',
      params: {
        mode: 'add-env',
        envCanonical: _vm.$route.params.envCanonical,
        backRouteTo: _vm.$route.name,
      },
    }}},[_vm._v(" "+_vm._s(_vm.$t('environment.create'))+"... ")]),_c('v-divider',{staticClass:"my-2 mx-0"}),(!_vm._.isEmpty(_vm.filteredEnvs))?_c('v-list',{staticClass:"env-menu__envs",attrs:{"dense":"","nav":"","data-cy":"env-list"}},_vm._l((_vm.filteredEnvs),function(env){return _c('div',{key:env.id},[_c('v-list-item',{directives:[{name:"hover-siblings",rawName:"v-hover-siblings"}],attrs:{"data-cy":"envs-list-item"},on:{"click":function($event){return _vm.goToEnv(env)}}},[_c('v-list-item-action',[_c('CyAvatar',{attrs:{"item":env,"sm":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(env.canonical)+" ")])],1),(env.paused)?_c('CyTag',{staticClass:"ml-1",attrs:{"small":"","variant":"default"}},[_vm._v(" "+_vm._s(_vm.$t('pipeline.paused'))+" ")]):_vm._e(),_c('v-list-item-action',{staticClass:"mr-0"},[(_vm.currentEnv.canonical === env.canonical)?_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" check ")]):_vm._e()],1)],1)],1)}),0):_c('div',{staticClass:"env-menu--empty px-4 py-2",attrs:{"data-cy":"no-envs-found"}},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('notFound'))}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }